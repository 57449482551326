<template>
  <PageLayout :headerText="headerText">
    <template v-slot:header>
      <div class="flex justify-end">
        <BaseButton @click="formVisible = true">Create employee</BaseButton>
      </div>
      <EmployeeFormModal @close="formVisible = false" :visible="formVisible" />
    </template>

    <template v-slot:main>
      <EmployeeTable class="mt-2" :employees="employees" />
    </template>
  </PageLayout>
</template>

<script lang="ts">
// components
import { defineComponent, ref } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import EmployeeTable from "@/features/employees/table/EmployeeTable.vue";
import EmployeeFormModal from "@/features/employees/EmployeeFormModal.vue";

// store
import useEmployeeStore from "@/store/useEmployeeStore";

export default defineComponent({
  name: "EmployeesPage",
  components: {
    PageLayout,
    EmployeeTable,
    EmployeeFormModal
  },
  setup() {
    const headerText = "Employees";

    const { employees } = useEmployeeStore({ allEmployees: true });

    const formVisible = ref(false);

    return {
      headerText,
      employees,
      formVisible
    };
  }
});
</script>

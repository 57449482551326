
import { defineComponent } from "vue";
// components
import FormModal from "@/components/modals/FormModal.vue";
import EmployeeForm from "@/features/employees/EmployeeForm.vue";

export default defineComponent({
  name: "EmployeeFormModal",
  components: {
    FormModal,
    EmployeeForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: { close: null },
  setup() {
    return {};
  }
});

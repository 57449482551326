
// components
import { defineComponent } from "vue";
// store
import useEmployeeStore, { createAbbreviation } from "@/store/useEmployeeStore";
// other
import useEmployeeForm from "./useEmployeeForm";
import { getRandomColor } from "@/utils/colorHelpers";

export default defineComponent({
  name: "EmployeeForm",
  components: {},
  setup(props, context) {
    // store
    const { onCreateEmployee, onCreateEmployeeSuccess, loading, error } = useEmployeeStore();

    // employee form hook
    const {
      formData,
      resetForm,
      employeeCreateSchema,
      validate,
      firstNameSchema,
      lastNameSchema,
      weeklyWorkingHoursSchema
    } = useEmployeeForm({ firstName: "", lastName: "", abbreviation: "", color: "", weeklyWorkingHours: "" });

    // Validate and create employee
    const onSubmit = () => {
      // create abbreviaiton and color
      formData.abbreviation = createAbbreviation(formData.firstName, formData.lastName);
      formData.color = getRandomColor();
      employeeCreateSchema
        .validate(formData)
        .then(() => {
          // execute api call
          onCreateEmployee(
            formData.firstName,
            formData.lastName,
            formData.abbreviation,
            formData.color,
            formData.weeklyWorkingHours
          );
        })
        .catch(() => (validate.value = true));
    };

    // close form and reset data
    const onClose = () => {
      validate.value = false;
      resetForm();
      context.emit("close");
    };

    // cleanup after successful action
    onCreateEmployeeSuccess(() => {
      console.info("Employee successfully created");
      onClose();
    });

    return {
      formData,
      validate,
      firstNameSchema,
      lastNameSchema,
      weeklyWorkingHoursSchema,
      onSubmit,
      onClose,
      loading,
      error
    };
  }
});


// components
import { defineComponent, ref } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import EmployeeTable from "@/features/employees/table/EmployeeTable.vue";
import EmployeeFormModal from "@/features/employees/EmployeeFormModal.vue";

// store
import useEmployeeStore from "@/store/useEmployeeStore";

export default defineComponent({
  name: "EmployeesPage",
  components: {
    PageLayout,
    EmployeeTable,
    EmployeeFormModal
  },
  setup() {
    const headerText = "Employees";

    const { employees } = useEmployeeStore({ allEmployees: true });

    const formVisible = ref(false);

    return {
      headerText,
      employees,
      formVisible
    };
  }
});

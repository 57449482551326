import { ref, reactive } from "vue";
import * as yup from "yup";

export interface EmployeeUpdateForm {
  firstName: string;
  lastName: string;
  abbreviation: string;
  color: string;
}

export interface CreateEmployeeForm extends EmployeeUpdateForm {
  weeklyWorkingHours: string;
}

export type EmployeeForm = EmployeeUpdateForm | CreateEmployeeForm;

/**
 * Employee form validation
 * @param initialData Initial data when modifying employee
 */
export default function useEmployeeForm<T extends object>(initialData: T) {
  const validate = ref(false);

  /**
   * Base properties
   * Using yup to generate a validation schema
   * https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
   */

  const firstNameSchema = yup.string().required("Please enter a first name");
  const lastNameSchema = yup.string();
  const abbreviationSchema = yup.string().required("Please enter a abbreviation");
  const colorSchema = yup.string().required("Please enter a color");
  const weeklyWorkingHoursSchema = yup
    .number()
    .max(99)
    .typeError("Please enter the weekly working hours");

  /**
   * Schemas
   */
  const employeeCreateSchema = yup.object().shape({
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    abbreviation: abbreviationSchema,
    color: colorSchema,
    weeklyWorkingHours: weeklyWorkingHoursSchema
  });

  const employeeUpdateSchema = yup.object().shape({
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    abbreviation: abbreviationSchema,
    color: colorSchema
  });

  const updateContractSchema = yup.object().shape({
    weeklyWorkingHours: weeklyWorkingHoursSchema
  });

  /**
   * Form data
   */
  // create reactive form state
  const formData = reactive({ ...initialData });

  function resetForm(data = initialData) {
    Object.assign(formData, data);
    validate.value = false;
  }

  return {
    formData,
    resetForm,
    validate,
    employeeCreateSchema,
    employeeUpdateSchema,
    updateContractSchema,
    firstNameSchema,
    lastNameSchema,
    weeklyWorkingHoursSchema,
    abbreviationSchema,
    colorSchema
  };
}

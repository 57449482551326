<template>
  <FormModal :visible="visible" :header="'Create employee'" :size="'2xl'" :clickOutside="true" @close="$emit('close')">
    <template #content>
      <EmployeeForm @close="$emit('close')" />
    </template>
  </FormModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import FormModal from "@/components/modals/FormModal.vue";
import EmployeeForm from "@/features/employees/EmployeeForm.vue";

export default defineComponent({
  name: "EmployeeFormModal",
  components: {
    FormModal,
    EmployeeForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: { close: null },
  setup() {
    return {};
  }
});
</script>
